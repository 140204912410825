.loginPage {
    background-color: $gray_tone;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .formContainer {
        width: 20%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 2px 1px #efefef;
        padding: 32px;
        border-radius: 8px;
        .logo {
            margin-bottom: 16px;
            margin-top: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .form {
            .formItem {
                .loginInp {
                    height: 40px;
                    width: 100%;
                }
                .submitBtn {
                    width: 100%;
                    height: 40px;
                    background-color: $orange_tone;
                    border: none;
                    color: #fff;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
