.customers {
    @import '../../components/common/common.scss';
}

.customerContactsForm {
    .ant-form-item {
        margin-bottom: 0 !important;
    }

    .delete {
        margin-top: 10px;
        background-color: red;
        color: #fff;
        border: none;

        &:hover {
            color: #fff;
        }
    }

    .addBtn {
        margin-top: 10px;
        background-color: $light_gray_tone;
        color: #fff;
        border: none;

        &:hover {
            color: #fff;
        }
    }
}