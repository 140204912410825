.layoutDashboard {
    width: 100%;
    .layoutDashTopSection {
        padding: 16px 0;
        gap: 20px;
        display: flex;
        background-color: #fff;
        width: 100%;
        box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.1);
        .row {
            width: 100%;
            flex-wrap: nowrap;
            align-items: center;
            .ant-picker {
                width: 100% !important;
                .ant-picker-input {
                    width: 100%;
                    input {
                        width: 100%;
                    }
                }
            }
            .clearFiltersBtn, .refreshPageBtn {
                border-radius: 6px !important;
                height: 30px;
                font-size: 12px;
                padding: 4px 8px;
                color: #fff;
                font-weight: 700;
                border: none;
                width: 50%;
                max-width: 120px;
            }
            .clearFiltersBtn {
                background-color: #ff0000;
            }
            .refreshPageBtn {
                background-color: orange;
            }
            .col {
                
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px;
                .menuName {
                    font-size: 12.5px;
                    color: $gray_tone;
                    margin-bottom: 6px;
                }
            }
        }
        
    }
    .dashboardSecondSection {
        
        width: 100%;
        .col {
            background-color: transparent;
            width: 100%;
            margin-top: 16px;
            .table_container_rate {
                margin-right: 2.5px;
                background-color: #fff;
                width: 100%;
            }
            .table_container_overal {
                margin-left: 2.5px;
                background-color: #fff;
                width: 100%;
                .ant-spin-container > div {
                    min-height: 0 !important;
                }
                  
            }
            .table_container_latest_feedback {
                @extend .table_container_rate;
                .ant-table-cell {
                    padding: 8px;
                }
            }

            .table_container_upcoming {
                @extend .table_container_overal;
                .ant-table-cell {
                    padding: 8px;
                }
            }
        }
    }
    .dashboardSecondSection {
        @extend .dashboardSecondSection;
    }
}
