.table_inside_container {
    padding: 16px;
    width: 100%;
    height: 416px;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.1);
    .overalDataTable {
        li:nth-child(odd) {
            background-color: #f9f9f9!important
        }
    }
    .ratePercent {
        margin: 0 auto;
    }
    tr:nth-child(even) {
        background-color: #f9f9f9
    }
    .clickable-row {
        cursor: pointer;
    }
    .tableHeadText {
        color: $light_gray_tone;
        text-align: center;
        font-size: 17.5px;
        font-weight: 400;
        padding: 0px;
        margin: 0px;
        padding-bottom: 8px;
    }

    .tablePagination {
        display: flex;
        justify-content: flex-end;
        padding: 8px 0px;
    }

    .tableHeadWithPagination {
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;

        .pagination {
            display: flex;
            align-items: center;

            .buttons {
                display: flex;
                align-items: center;

                .expired {
                    background-color: $light_gray_tone_2;
                    height: 25px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    border: none;
                    margin-right: 4px;
                    padding: 0px 6px;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .upcoming {
                    @extend .expired;
                    background-color: $blue_tone;
                }
                .selectFilter {
                    height: 27px !important;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    margin-right: 4px;
                    padding: 0 6px;
                    width: 100px;
                    
                    .ant-select-selector {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        border-radius: 0 !important;
                        width: 100%;
                    }
                    
                }
                .clearExpired {
                    @extend .upcoming;
                    background-color: red;
                }
            }
        }
    }

    .menuName {
        font-size: 12.5px;
        font-weight: 400;
        color: $light_gray_tone;
        width: 100px;
    }

    .menuValue {
        font-size: 12px;
        font-weight: 500;
        color: $light_gray_tone;
        font-weight: 400;
    }

    .menuValueDarkGray {
        @extend .menuValue;
        color: $dark_gray_tone;
        font-size: 12px !important;
        font-weight: 400;
    }
}

.menuValueDarkGray {
    @extend .menuValue;
    color: $dark_gray_tone;
    font-size: 12px !important;
    font-weight: 400;
}

.upcoming_table,
.latest_feedback_table {
    @extend .table_inside_container;
    height: 520px !important;
}

.overalDataTable {
    @extend .table_inside_container;
    height: 416px !important;
}

.layoutDashGeneralTable {
    margin-top: 16px;
    background-color: #fff;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.1);
    .testRow {
        height: 10px !important;
    }

    .ant-table-cell {
        padding: 4px !important;
    }
    .sortBtn {
        background: none;   
        border: none;
        font-size: 12px;
        display: grid;
        place-content: center;
        box-shadow: none;
        height: auto;
        padding: 0;
    }
}

// userManagement

.userManagementContent {
    margin-top: 16px;
    background-color: #fff;

    .menuName {
        font-size: 12px;
        font-weight: 400;
        color: $light_gray_tone;
    }

    .searchInputContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 16px;
        gap: 10px;
        .searchInput {
            width: 300px !important;
        }

        .licenceStatusFilter {
            width: 150px;
            height: 25px;
            
            .ant-select-selector {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0;
            }
            
        }
    }
}

.companyLink {
    color: #607d8b;
    cursor: pointer;
}

.ant-table-cell {
    font-weight: 400 !important;
    font-size: 12px;
}

.no-wrap {
    white-space: nowrap; /* Prevent wrapping */
    word-break: keep-all; /* Prevent hyphenation */
}

.companyColumn {
    max-width: 100px;
}

.searchInput {
    height: 25px;
    border-radius: 1px;
    font-size: 12px;
    padding: 4px 7px;
}
