#rootLayout {
    min-height: 100vh;
    .rootLayoutSider {
        background-color: $gray_tone;
        height: 100%;
        .logo {
            background-color: $orange_tone;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                object-fit: contain;
                width: 100px;
                height: 100px;
            }
        }
        .menuHeaderText {
            padding: 12px 16px;
            color: #fff;
        }
        .rootLayoutMenu {
            background-color: $gray_tone;
        }
    }
    .rootLayoutContent {
        padding: 16px;
    }
}
