.modal {
    .ant-modal-content {
        padding: 0px;
    }
    .ant-modal-footer {
        padding-bottom: 16px;
        padding-right: 16px;
    }
    .ant-modal-close-x {
        color: #fff;
        padding-top: 6px;
    }
    .modalHeader {
        height: 67px;
        background-color: $dark_blue_tone;
        display: flex;
        align-items: center;
        .headerContent {
            font-weight: bold;
            font-size: 17.5px;
            color: #fff;
            padding: 20px 15px;
            font-weight: 400;
        }
    }
    .modalFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;
        .delete {
            background-color: red;
            font-weight: bold;
            color: #fff;
            border: none;
        }
        .save {
            background-color: $green_tone;
            font-weight: bold;
            color: #fff;
            border: none;
        }
    }
}
