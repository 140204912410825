


@media (max-width: 768px)
    .row
        flex-direction: column
        .col
            width: 100% !important
    .dashboardSecondSection
        flex-direction: column
        .ant-col-xs-12
            max-width: 100% !important
    .latest_feedback_table 
        .ant-table 
            overflow-y: scroll !important
        
    