.layoutHeader {
    background-color: $gray_tone !important;
    // padding: 16px !important;
    padding: 0px 16px !important;

    .headerContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .userProfile {
            color: #fff;
            display: flex;
            align-items: center;

            .username {
                padding-right: 6px;

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }

            .outIcon {
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }
    }
}

.commonPageHeader {
    background-color: #fff;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headText {
        color: $light_gray_tone;
        font-weight: 300;
        font-size: 24px;
        margin: 0px;
        padding: 0px;
    }

    .subText {
        font-size: 12px;
        font-style: italic;
        color: $dark_gray_tone;
    }

    .addBtn {
        background-color: $green_tone;
        color: #fff;
        border-color: $green_tone;

        &:hover {
            opacity: 0.8;
        }
    }
}

.tableEditBtn {
    background-color: #ffc05c;
    color: #fff;
    border: none;

    &:hover {
        color: #fff !important;
        opacity: 0.8;
    }
}

.tableDelBtn {
    @extend .tableEditBtn;
    background-color: #ff4d4f;

    &:hover {
        color: #fff !important;
        opacity: 0.8;
    }
}

.ant-btn {
    border-radius: 0px !important;
}

// modal forms styles
@import '../forms/userManagementForms.scss';
@import '../forms/emailTrackerForm.scss';
@import '../forms/customersForms.scss';
@import '../forms/companyManagementForms.scss';
@import '../forms/aiManagementForm.scss';