    .countryForExpireSpinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100%;
    }

    .countryForExpireContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 10px 20px;



        h4 {
            font-weight: 400;
            font-size: 18px;
            color: #607d8b;
            margin: 0 0 16px 0;

        }

        table {
            border-collapse: collapse;

            tr {
                td {
                    border: 1px solid #ddd;
                    width: 100%;
                    padding: 10px;

                    &:nth-child(2) {
                        min-width: 220px;
                    }
                }

                &:nth-child(odd) {
                    background-color: #f9f9f9;
                }
            }
        }
    }