body {
    padding: 0px;
    margin: 0px;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

* {
    font-family: 'Roboto', sans-serif;
}

@import './variable.scss';
@import './media.sass';

// pages

@import './pages/desktop/mainPage.scss';
@import './pages/desktop/dashboard.scss';
@import './pages/desktop/userManagement.scss';
@import './pages/desktop/emailTracker.scss';
@import './pages/desktop/customers.scss';
@import './pages/desktop/sales.scss';
@import './pages/auth/login.scss';

// components

@import './components/select/selects.scss';
@import './components/tables/tables.scss';
@import './components/common/common.scss';
@import './components/custom/modal.scss';
@import './components/list/list.scss';

// graphs
@import './components/graph/sliChart.scss';

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
  


/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
  

 
/* Handle */
::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}
  


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: grey
}
 