.userManagementForms {
    padding: 16px;
    .tabMenuContainer {
        display: flex;
        align-items: center;
        color: #000;
        font-size: 12.5px;
        .tabMenuName {
            padding-left: 4px;
        }
    }
    .row {
        .col {
            padding: 10px;
            .labelName {
                font-size: 12.5px;
                color: $dark_gray_tone;
            }
            .formItem {
                padding: 0px !important;
                margin: 0px !important;
            }
        }
    }
}
