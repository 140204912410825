.countrySelect {
    width: 100%;
}

.companySelect {
    @extend .countrySelect;
}

.predefinedSelect {
    @extend .countrySelect;
}

.dateRangeSelect {
    @extend .countrySelect;
}
